import { Controller } from "@hotwired/stimulus"

import { nextAnimationFrame } from "../utils/animation"

// Connects to data-controller="products-slider"
export default class extends Controller {
  static targets = [ "slide", "button", "buttonsContainer", "slidesContainer" ]
  static values = {
    activeSlideIndex: Number
  }

  get slideControllers() {
    return this.slideTargets
      .map((el) => this.application.getControllerForElementAndIdentifier(el, "products-slider--slide"))
      .filter((controller) => controller)
  }

  connect() {

  }

  openSlide(e) {
    const { target } = e
    const buttonTarget = target.closest("[data-products-slider-target='button']")
    const index = parseInt(buttonTarget.dataset.productsSliderSlideIndex)

    this.activeSlideIndexValue = index
  }

  next() {
    this.activeSlideIndexValue = (this.activeSlideIndexValue + 1) % this.slideTargets.length
  }

  prev() {
    const prevIndex = (this.activeSlideIndexValue == 0) ? this.slideTargets.length - 1 : this.activeSlideIndexValue - 1

    this.activeSlideIndexValue = prevIndex
  }

  async activeSlideIndexValueChanged() {
    const index = this.activeSlideIndexValue

    this.buttonTargets.forEach((el) => {
      const active = el.dataset.productsSliderSlideIndex == index
      el.classList.toggle("bg-gray-900", active)

      if (active){
        this._scrollIntoView(el)
      }
    })

    await nextAnimationFrame()

    this.slideControllers.forEach((slide) => slide.activateIndex(index))
  }

  updateSlideContainerHeight(height) {
    this.slidesContainerTarget.style.height = `${height}px`
  }

  _scrollIntoView(button) {
    const buttonsContainer = this.buttonsContainerTarget
    const buttonContainerWidth = buttonsContainer.clientWidth
    const buttonOffset = button.offsetLeft
    const buttonWidth = button.clientWidth

    const scrollPosition = buttonOffset - buttonContainerWidth / 2 + buttonWidth / 2

    buttonsContainer.scrollTo({
      left: scrollPosition,
      behavior: "smooth"
    })
  }
}