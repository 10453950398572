import { Controller } from "@hotwired/stimulus"
import { throttle } from "throttle-debounce"
const baseClasses = ["transform", "transition", "ease-out", "duration-1000"]

// Connects to data-controller="parallax"
export default class extends Controller {
  static values = {
    scale: { type: Number, default: 110 },
    direction: { type: String, default: "both" },
    invertX: { type: Boolean, default: true },
    invertY: { type: Boolean, default: true },
    active: Boolean
  }

  get enableX() { return this.directionValue == "both" || this.directionValue == "horizontal" }
  get enableY() { return this.directionValue == "both" || this.directionValue == "vertical" }

  initialize() {
    this.update = this.update.bind(this)
  }

  connect() {
    this.element.classList.add(...baseClasses)
    this.activeValue = true
  }

  disconnect() {
    this.element.classList.remove(...baseClasses)
    this.activeValue = false
  }

  scaleValueChanged() {
    this._applyScaling()
  }

  activeValueChanged() {
    if (this.activeValue) {
      window.addEventListener("mousemove", this.update)
    } else {
      window.removeEventListener("mousemove", this.update)
    }
  }

  update(e) {
    this.lastPositionEvent = e

    if (!this.animationFrameRequested) {
      this.animationFrameRequested = true

      requestAnimationFrame(() => {
        this._updatePosition()
        this.animationFrameRequested = false
      })
    }
  }

  _updatePosition() {
    const e = this.lastPositionEvent

    if (this.enableX)
      this._updateTranslate("x", e.clientX, window.innerWidth, this.invertXValue)

    if (this.enableY)
      this._updateTranslate("y", e.clientY, window.innerHeight, this.invertYValue)

  }

  _updateTranslate(property, current, extent, invert) {
    const percent = (current / extent) * 2 - 1
    const maxMovePercentage = (this.scaleValue - 100) / 2
    const moveToPercentage = percent * maxMovePercentage * (invert ? -1 : 1)

    this._setProperty(`translate-${property}`, `${moveToPercentage}%`)
  }

  _applyScaling() {
    this._setProperty("scale-x", this.scaleValue / 100.0)
    this._setProperty("scale-y", this.scaleValue / 100.0)
  }

  _setProperty(named, value) {
    this.element.style.setProperty(`--tw-${named}`, value)
  }
}
