import { Controller } from '@hotwired/stimulus'

import { dispatchEvent } from 'utils/events'

export default class extends Controller {
  static targets = [
    "template", "collection", "item",
    // Dummy targets to shut up warnings
    "id", "destroy"
  ]

  get autofocus() {
    const autofocus = this.data.get("autofocus")

    if (autofocus) {
      return autofocus == "true"
    } else {
      return true
    }
  }

  add(e) {
    e.preventDefault()

    const clickTarget = e.target
    const buttonTarget = clickTarget.closest("*[data-action~='nested-fields#add']")

    const templateName = this._getTemplateName(buttonTarget)

    this.addItemForTemplate(templateName)
  }

  addItemForTemplate(templateName) {
    const fieldsHTML = this._prepareNewFieldsForTemplate(templateName)
    const itemTarget = this._insertFieldsHTML(fieldsHTML)

    if (this.autofocus) {
      this._applyAutofocus(itemTarget)
    }

    this._triggerInsertEvent(itemTarget)
  }

  destroy(e) {
    e.preventDefault()

    const itemTarget = e.target.closest("*[data-nested-fields-target='item']")

    this._tryDestroyByHiding(itemTarget) || this._destroyByRemoving(itemTarget)
    this._triggerDestroyEvent(itemTarget)
  }

  // ===================
  // = Fields Handling =
  // ===================

  // Creation
  _prepareNewFieldsForTemplate(templateName) {
    const templateTarget = this._getTemplateNamed(templateName)

    const now = new Date()
    const identifier = now.getTime()
    const templateHTML = templateTarget.innerHTML

    const html = templateHTML.replace(/IDENTIFIER/g, identifier)
    return html
  }

  _insertFieldsHTML(html) {
    this.collectionTarget.insertAdjacentHTML("beforeend", html)

    return this._getInsertedItem()
  }

  _getInsertedItem() {
    const { itemTargets } = this
    const itemTarget = itemTargets[itemTargets.length - 1]

    return itemTarget
  }

  _getTemplateName(button) {
    return button.dataset.nestedFieldsTemplate
  }

  _getTemplateNamed(templateName) {
    if (!templateName) {
      return this.templateTarget
    } else {
      return this.templateTargets.find((target) => target.dataset.nestedFieldsTemplate == templateName)
    }
  }

  _applyAutofocus(itemTarget) {
    if (!itemTarget) { return }

    const autofocusTarget = itemTarget.querySelector("[autofocus]")

    if (autofocusTarget) {
      autofocusTarget.focus()
    }
  }

  // Removal


  _tryDestroyByHiding(itemTarget) {
    const recordId = this._getRecordIdOfItem(itemTarget)

    if (recordId != null && recordId != "") {
      this._destroyByHiding(itemTarget)

      return true
    } else {
      return false
    }
  }

  _destroyByHiding(itemTarget) {
    const destroyInput = itemTarget.querySelector("[data-nested-fields-target=destroy]")

    destroyInput.value = "true"
    itemTarget.classList.add("hidden")
  }

  _destroyByRemoving(itemTarget) {
    itemTarget.remove()
  }

  _getRecordIdOfItem(itemTarget) {
    const idInputTarget = itemTarget.querySelector("[data-nested-fields-target=id]")

    if (idInputTarget) {
      return idInputTarget.value
    } else {
      return null
    }
  }

  // Events

  _triggerDestroyEvent(itemTarget) {
    dispatchEvent(itemTarget, "nested-fields:destroy")
    this._triggerChangeEvent()
  }

  _triggerInsertEvent(itemTarget) {
    dispatchEvent(itemTarget, "nested-fields:inserted")
    this._triggerChangeEvent()
  }

  _triggerChangeEvent() {
    dispatchEvent(this.element, "nested-fields:change")
  }
}