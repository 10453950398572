import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="logo"
export default class extends Controller {
  static values = {
    topOffset: { type: Number, default: 10 },
    isSmall: { type: Boolean, default: false }
  }
  static classes = ["large", "small"]

  connect() {
    this.scrollListener = () => requestAnimationFrame(this.updateIsSmall.bind(this))

    window.addEventListener("scroll", this.scrollListener, { passive: true })

    this.updateIsSmall()
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollListener)
  }

  updateIsSmall() {
    const topOffset = this.topOffsetValue

    this.isSmallValue = document.body.scrollTop > topOffset || document.documentElement.scrollTop > topOffset
  }

  isSmallValueChanged() {
    this.updateClasses()
  }

  updateClasses() {
    const { smallClasses, largeClasses, isSmallValue } = this
    const classList = this.element.classList

    if (isSmallValue) {
      classList.add(...smallClasses)
      classList.remove(...largeClasses)
    } else {
      classList.remove(...smallClasses)
      classList.add(...largeClasses)
    }

  }
}

