
export async function nextAnimationFrame() {
  return new Promise(resolve => {
    requestAnimationFrame(() => {
      requestAnimationFrame(resolve)
    })
  })
}

export function getTransitionDuration(element) {
  const computedStyle = getComputedStyle(element)

  const duration = Number(computedStyle.transitionDuration.split(',')[0].replace('s', '')) * 1000
  const delay = Number(computedStyle.transitionDelay.split(',')[0].replace('s', '')) * 1000

  return duration + delay
}

export async function afterTransition(element) {
  const duration = getTransitionDuration(element)


  return new Promise(resolve => {
    if (duration > 0){
      setTimeout(() => resolve(duration), duration)
    } else {
      resolve(duration)
    }
  })
}