import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleButton", "toggleable"]
  static values = {
    visible: Boolean
  }
  static classes = ["toggleButtonActive"]

  connect() {
  }

  toggle() {
    this.visibleValue = !this.visibleValue
  }

  visibleValueChanged() {
    this.render()
  }

  render() {
    const toggleButtonCL = this.toggleButtonTarget.classList
    this.toggleButtonActiveClasses.forEach((cssClass) => toggleButtonCL.toggle(cssClass, this.visibleValue))

    this.toggleableTarget.classList.toggle("hidden", !this.visibleValue)
  }
}
