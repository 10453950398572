import { Controller } from "@hotwired/stimulus"
import { loader } from 'vendor/google_maps'

const austria = { lat: 47.612328, lng: 13.787016 }

// Connects to data-controller="map"
export default class extends Controller {
  static values = {
    markers: Array,
    zoom: { type: Number, default: 7 },
    center: { type: Object, default: austria },
    locationSearchField: Boolean,
    initialZoomToMarkers: Boolean
  }

  connect() {
    this._createMap()
  }

  disconnect() {
    if (this.map) {
      this.clearMarkers()

      delete this.map;

      this.map = null
    }
  }

  async _createMap() {
    await loader.load()

    const options = {
      center: this.centerValue,
      zoom: this.zoomValue,
    }

    this.map = new google.maps.Map(this.element, {
      center: this.centerValue,
      zoom: this.zoomValue,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false
    });

    this.infoWindow = new google.maps.InfoWindow()

    this.map.addListener("zoom_changed", () => this.zoomValue = this.map.getZoom())
    this.map.addListener("click", this.emitMapClicked.bind(this))
    this.updateMarkers()

    if (this.locationSearchFieldValue) {
      this.createLocationSearchFieldWidget()
    }

    if (this.initialZoomToMarkersValue){
      this.focusMarkers()
    }

    this.trigger("map:created")
  }

  fitBounds(bounds, padding) {
    if (!this.map) return

    this.map.fitBounds(bounds, padding)
  }

  markersValueChanged() {
    this.updateMarkers()
  }

  zoomValueChanged() {
    if (!this.map) return;
    if (this.map.getZoom == this.zoomValue) return;

    this.map.setZoom(this.zoomValue)
  }

  centerValueChanged() {
    if (!this.map) return;

    this.map.panTo(this.centerValue)
  }

  // ==================
  // = Event Handling =
  // ==================

  emitMapClicked(e) {
    this.trigger("click", e)
  }

  trigger(name, detail) {
    const event = new CustomEvent(`map:${name}`, { detail })

    this.element.dispatchEvent(event)
  }

  // ================================
  // = Location Autocomplete Widget =
  // ================================

  createLocationSearchFieldWidget() {
    const div = document.createElement("div")
    div.classList.add("mt-2")

    const input = document.createElement("input")
    input.type = "search"
    input.classList.add("bg-white", "text-black", "w-96")
    input.addEventListener("keydown", (e) => {
      if (e.which == 13)
        e.preventDefault()
    })
    input.autocomplete = "off"
    input.autocorrect = "off"

    div.appendChild(input)

    this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(div)

    const options = {
      componentRestrictions: { country: "at" },
      fields: ["geometry"],
      strictBounds: false,
    };

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace()

      if (!place || !place.geometry || !place.geometry.viewport) return

      this.map.fitBounds(place.geometry.viewport)
    })
  }

  // ===================
  // = Marker Handling =
  // ===================

  updateMarkers() {
    if (!this.map) return;

    this.clearMarkers()

    this.googleMarkers = this.markersValue.map((m) => this.createMarker(m))
  }

  clearMarkers() {
    if (!this.googleMarkers) return

    this.googleMarkers.forEach((m) => m.setMap(null))
  }

  createMarker(options) {
    const { position, infoContent, draggable, draggableCursor } = options

    const marker = new google.maps.Marker({
      position,
      draggable,
      draggableCursor,
      detail: options,
      map: this.map,
    });

    if (options.infoContent)
      marker.addListener("click", () => this.displayInfoWindowForMarker(marker))

    if (options.draggable)
      marker.addListener("dragend", () => this.handleMarkerDragged(marker))

    if (options.infoWindowOpened)
      this.displayInfoWindowForMarker(marker)

    return marker
  }

  focusMarkers() {
    let bounds = new google.maps.LatLngBounds();

    this.googleMarkers.forEach((marker) => bounds.extend(marker.getPosition()))
    this.fitBounds(bounds);
  }

  displayInfoWindowForMarker(marker) {
    this.infoWindow.setContent(marker.detail.infoContent)
    this.infoWindow.open({
      anchor: marker,
      map: this.map,
    })
  }

  handleMarkerDragged(marker) {
    this.synchronizeMarkerValuesWithMarkersFromMap()

    this.trigger("marker-dragged", {
      marker: marker.detail,
      position: {
        lat: marker.position.lat(),
        lng: marker.position.lng()
      }
    })
  }

  synchronizeMarkerValuesWithMarkersFromMap() {
    const updatedValues = this.googleMarkers.map((marker) => { return { ...marker.detail, position: { lat: marker.position.lat(), lng: marker.position.lng() } } })

    this.markersValue = updatedValues
  }
}

