import { Controller } from "@hotwired/stimulus"

import { addClasses, removeClasses } from "../utils/classes"
import { dispatchEvent } from "../utils/events"
import { nextAnimationFrame, afterTransition } from "../utils/animation"

// Connects to data-controller="transition"
export default class extends Controller {
  static values = {
    transitioned: Boolean,
    leaveAfter: Number,
    transitioning: Boolean,
    transitionableId: String,
  }
  static targets = ["transitionable"]
  static classes = ["hidden", "transitioned", "enterActive", "enterFrom", "enterTo", "leaveActive", "leaveFrom", "leaveTo"]

  get transitionTarget() {
    if (this.hasTransitionableTarget){
      return this.transitionableTarget
    } else if (this.transitionableIdValue) {
      return document.getElementById(this.transitionableIdValue)
    } else {
      return this.element
    }
  }

  connect() {
    this.data.set("hiddenClass", "hidden")

    if (this.transitionedValue) {
      removeClasses(this.transitionTarget, this.hiddenClasses)
    }
  }

  async enter() {
    if (this.transitionedValue || this.transitioningValue) { return false }
    this.transitionedValue = true
    this.transitioningValue = true

    const target = this.transitionTarget

    dispatchEvent(target, "transition:enter")

    if (this.hiddenClasses.length > 0) {
      removeClasses(target, this.hiddenClasses)
    }

    await this.transition(target, this.enterFromClasses, this.enterActiveClasses, this.enterToClasses, this.hiddenClasses, true, true)

    this.transitioningValue = false

    dispatchEvent(target, "transition:entered")

    if (this.leaveAfterValue > 0) {
      setTimeout(() => {
        this.leave()
      }, this.leaveAfterValue)
    }
  }

  async leave() {
    if (!this.transitionedValue || this.transitioningValue) { return false }
    this.transitionedValue = false
    this.transitioningValue = true

    const target = this.transitionTarget

    dispatchEvent(target, "transition:leave")
    await this.transition(target, this.leaveFromClasses, this.leaveActiveClasses, this.leaveToClasses, this.hiddenClasses, true, true)

    if (this.hiddenClasses.length > 0) {
      addClasses(target, this.hiddenClasses)
    }
    this.transitioningValue = false
    dispatchEvent(target, "transition:left")

  }

  async toggle() {
    if (this.transitionedValue) {
      this.leave()
    } else {
      this.enter()
    }
  }

  async transition(element, initialClasses, activeClasses, endClasses, hiddenClass, preserveOriginalClass, removeEndClasses) {
    // if there's any overlap between the current set of classes and initialClasses/activeClasses/endClasses,
    // we should remove them before we start and add them back at the end
    const stashedClasses = []
    if (preserveOriginalClass) {
      initialClasses.forEach(cls => element.classList.contains(cls) && cls !== hiddenClass && stashedClasses.push(cls))
      activeClasses.forEach(cls => element.classList.contains(cls) && cls !== hiddenClass && stashedClasses.push(cls))
      endClasses.forEach(cls => element.classList.contains(cls) && cls !== hiddenClass && stashedClasses.push(cls))
    }

    // Add initial class before element start transition
    addClasses(element, initialClasses)

    // remove the overlapping classes
    removeClasses(element, stashedClasses)

    // Add active class before element start transition and maintain it during the entire transition.
    addClasses(element, activeClasses)

    await nextAnimationFrame()

    // remove the initial class on frame after the beginning of the transition
    removeClasses(element, initialClasses)

    // add the endClass on frame after the beginning of the transition
    addClasses(element, endClasses)

    // dynamically compute and wait for the duration of the transition from the style of the element
    await afterTransition(this.transitionTarget)

    // remove both activeClasses and endClasses
    removeClasses(element, activeClasses)
    if (removeEndClasses) {
      removeClasses(element, endClasses)
    }

    // restore the overlaping classes
    addClasses(element, stashedClasses)
  }
}
