import { Controller } from "@hotwired/stimulus"
import { loader } from 'vendor/google_maps'

// Connects to data-controller="store_finder"
export default class extends Controller {
  static values = {
    stores: Array
  }
  static targets = [ "map", "search", "typeSelect" ]

  get mapController() { return this.application.getControllerForElementAndIdentifier(this.mapTarget, "map") }
  get selectedType() { return this.typeSelectTarget.value }

  connect() {
    this.setupPlaceAutocomplete()
    this.updateMarkers()
  }

  async setupPlaceAutocomplete() {
    await loader.load()

    const options = {
      componentRestrictions: { country: "at" },
      fields: ["geometry"],
      strictBounds: false,
    };

    this.autocomplete = new google.maps.places.Autocomplete(this.searchTarget, options);
    this.autocomplete.addListener("place_changed", this.placeChanged.bind(this))
  }

  placeChanged() {
    const place = this.autocomplete.getPlace()

    if (!place || !place.geometry || !place.geometry.viewport) return

    const { mapController } = this
    const { viewport } = place.geometry

    if (mapController && viewport) {
      mapController.fitBounds(viewport, {top: 120, bottom: 50, left: 50, right: 50})
    }
  }

  updateMarkers() {
    const stores = this.filteredStores()

    this.mapTarget.dataset.mapMarkersValue = JSON.stringify(stores.map((store) => store.marker))
  }

  filteredStores() {
    let stores = this.storesValue

    const { selectedType } = this

    if (selectedType) {
      stores = stores.filter((store) => store.type == selectedType )
    }


    return stores;
  }

}
