import { config } from "trix"

function fa_icon(name) { return `<i class='fa fa-${name}'></i>` }

function heading_icon(level) {
  return `<span class="fa-layers fa-fw">
    <i class="fa fa-heading" data-fa-transform='left-5' style='vertical-align: middle;'></i>
    <span class="fa-layers-text" data-fa-transform='right-6 down-3 shrink-2'>${level}</span>
  </span>`
}

const { lang } = config

    // <div class="trix-button-row">
//       <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="#{lang.bold}" tabindex="-1">#{lang.bold}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="#{lang.italic}" tabindex="-1">#{lang.italic}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" title="#{lang.strike}" tabindex="-1">#{lang.strike}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="#{lang.link}" tabindex="-1">#{lang.link}</button>
//       </span>
//       <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" title="#{lang.heading1}" tabindex="-1">#{lang.heading1}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="#{lang.quote}" tabindex="-1">#{lang.quote}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="#{lang.code}" tabindex="-1">#{lang.code}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="#{lang.bullets}" tabindex="-1">#{lang.bullets}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="#{lang.numbers}" tabindex="-1">#{lang.numbers}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="#{lang.outdent}" tabindex="-1">#{lang.outdent}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="#{lang.indent}" tabindex="-1">#{lang.indent}</button>
//       </span>
//       <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="#{lang.attachFiles}" tabindex="-1">#{lang.attachFiles}</button>
//       </span>
//       <span class="trix-button-group-spacer"></span>
//       <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="#{lang.undo}" tabindex="-1">#{lang.undo}</button>
//         <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="#{lang.redo}" tabindex="-1">#{lang.redo}</button>
//       </span>
//     </div>
//     <div class="trix-dialogs" data-trix-dialogs>
//       <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
//         <div class="trix-dialog__link-fields">
//           <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="#{lang.urlPlaceholder}" aria-label="#{lang.url}" required data-trix-input>
//           <div class="trix-button-group">
//             <input type="button" class="trix-button trix-button--dialog" value="#{lang.link}" data-trix-method="setAttribute">
//             <input type="button" class="trix-button trix-button--dialog" value="#{lang.unlink}" data-trix-method="removeAttribute">
//           </div>
//         </div>
//       </div>
//     </div>

config.toolbar = {
  getDefaultHTML: () => {
    const template = `
    <div class='bg-black py-2 px-2 '>
      <div class="flex flex-wrap w-100">
        <div class="btn-group my-1 mr-5" data-trix-button-group="text-tools">
          <button type="button" class="btn btn-primary" data-trix-attribute="bold" data-trix-key="b" title="${lang.bold}" tabindex="-1">${fa_icon("bold")}</button>
          <button type="button" class="btn btn-primary" data-trix-attribute="italic" data-trix-key="i" title="${lang.italic}" tabindex="-1">${fa_icon("italic")}</button>
          <button type="button" class="btn btn-primary" data-trix-attribute="underline" data-trix-key="u" title="${lang.underline}" tabindex="-1">${fa_icon("underline")}</button>
          <button type="button" class="btn btn-primary" data-trix-attribute="strike" title="${lang.strike}" tabindex="-1">${fa_icon("strikethrough")}</button>
          <button type="button" class="btn btn-primary" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="${lang.link}" tabindex="-1">${fa_icon("link")}</button>
        </div>
        <div class="btn-group my-1 mr-5" data-trix-button-group="block-tools">
          <button type="button" class="btn btn-primary" data-trix-attribute="heading1" title="${lang.heading1}" tabindex="-1">${fa_icon("heading")}</button>
          <button type="button" class="btn btn-primary" data-trix-attribute="quote" title="${lang.quote}" tabindex="-1">${fa_icon("quote-right")}</button>
          <button type="button" class="btn btn-primary" data-trix-attribute="code" title="${lang.code}" tabindex="-1">${fa_icon("code")}</button>
          <button type="button" class="btn btn-primary" data-trix-attribute="bullet" title="${lang.bullets}" tabindex="-1">${fa_icon("list-ul")}</button>
          <button type="button" class="btn btn-primary" data-trix-attribute="number" title="${lang.numbers}" tabindex="-1">${fa_icon("list-ol")}</button>
          <button type="button" class="btn btn-primary" data-trix-action="decreaseNestingLevel" title="${lang.outdent}" tabindex="-1">${fa_icon("outdent")}</button>
          <button type="button" class="btn btn-primary" data-trix-action="increaseNestingLevel" title="${lang.indent}" tabindex="-1">${fa_icon("indent")}</button>
        </div>
        <button type="button" class="btn btn-primary mr-5 my-1" data-trix-action="attachFiles" title="Attach Files" tabindex="-1">${fa_icon("paperclip")}</button>
        <div class="btn-group ml-auto my-1" data-trix-button-group="history-tools">
          <button type="button" class="btn btn-primary" data-trix-action="undo" data-trix-key="z" title="${lang.undo}" tabindex="-1">${fa_icon("undo")}</button>
          <button type="button" class="btn btn-primary" data-trix-action="redo" data-trix-key="shift+z" title="${lang.redo}" tabindex="-1">${fa_icon("redo")}</button>
        </div>
      </div>
      <div class="absolute bg-white shadow" data-trix-dialogs>
        <div class="p-3 bg-secondary shadow border rounded" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <input type="url" name="href" class="form-control" placeholder="${lang.urlPlaceholder}" aria-label="${lang.url}" required data-trix-input>

          <div class='mt-2 flex'>
            <input type="button" class="btn btn-primary mr-3" value="${lang.link}" data-trix-method="setAttribute">
            <input type="button" class="btn btn-primary" value="${lang.unlink}" data-trix-method="removeAttribute">
          </div>
        </div>
      </div>
    </div>`

    return template
  }
}