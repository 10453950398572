import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    isOpen: Boolean
  }

  connect() {
    if (!this.isOpen) {
      this.element.classList.toggle("hidden", true)
    }
  }

  open() {
    this.isOpen = true

    this.element.classList.toggle("hidden", false)
  }

  close() {
    this.isOpen = false

    this.element.classList.toggle("hidden", true)
  }


  closeOnSuccessfulSubmission(e) {
    console.log(e)
    if (e.detail.success) {
      this.close()
    }
  }
}
