import { Controller } from "@hotwired/stimulus"
import { loader } from "vendor/google_maps"
import { post } from "@rails/request.js"

// Connects to data-controller="store-form"
export default class extends Controller {
  static targets = ["map", "addressInput", "latitudeInput", "longitudeInput"]
  static values = {
    geocodingUrl: String
  }

  get latitude()  { return parseFloat(this.latitudeInputTarget.value) }
  set latitude(val) { this.latitudeInputTarget.value = val }

  get longitude() { return parseFloat(this.longitudeInputTarget.value) }
  set longitude(val) { this.longitudeInputTarget.value = val }

  get position()  {
    if (this.latitude && this.longitude) {
      return { lat: this.latitude, lng: this.longitude }
    } else {
      return null
    }
  }

  get address() { return this.addressInputTarget.value }
  set address(value) { this.addressInputTarget.value = value }

  connect() {
    this.updateMarkerWithInputs()
    this.focusMarker()
  }

  async convertAddressToGPS() {
    const { address, geocodingUrlValue } = this

    const resp = await post(geocodingUrlValue, {
      body: { geocoding: { address, reverse: false } }
    })

    if (resp.ok) {
      const json = await resp.json

      if (json){
        const { lat, lng } = json.position

        this.latitude  = lat
        this.longitude = lng

        this.updateMarkerWithInputs()
        this.focusMarker()
      }
    }
  }

  async convertGPSToAddress() {
    const { latitude, longitude, geocodingUrlValue } = this

    const resp = await post(geocodingUrlValue, {
      body: { geocoding: { latitude, longitude, reverse: true } }
    })

    if (resp.ok) {
      const json = await resp.json

      if (json) {
        this.address = json.address
      }
    }
  }

  focusMarker() {
    const { position } = this

    if (!position) return

    this.mapTarget.dataset.mapCenterValue = JSON.stringify(this.position)
  }

  updateMarkerWithInputs() {
    const { position } = this

    if (!position) return

    const marker = { position, draggable: true, draggableCursor: "pointer" }

    this.mapTarget.dataset.mapMarkersValue = JSON.stringify([marker])
  }

  updateInputsWithMarkerPosition(e) {
    const { lat, lng } = e.detail.position

    this.latitude = lat
    this.longitude = lng
  }


  handleMapClick(e) {
    const originalEvent = e.detail

    if (originalEvent.domEvent.shiftKey) {
      const { latLng } = originalEvent

      this.latitude = latLng.lat()
      this.longitude = latLng.lng()

      this.updateMarkerWithInputs()
    }
  }

}
