import { Controller } from "@hotwired/stimulus"

import { nextAnimationFrame } from "../../utils/animation"

// Connects to data-controller="products-slider--slide"
export default class extends Controller {
  static values = {
    active: Boolean,
    index: Number,
  }

  static targets = ["transition"]

  get sliderElement() { return this.element.closest("[data-controller~='products-slider']") }
  get sliderController() { return this.application.getControllerForElementAndIdentifier(this.sliderElement, "products-slider") }

  get transitionControllers() { return this.transitionTargets.map((el) => this.application.getControllerForElementAndIdentifier(el, "transition")).filter((c) => c) }

  initialize() {
    this.resizeListener = this._handleResize.bind(this)
  }

  connect() {
    //console.log("!!!")
  }

  disconnect() {
    this._detachResizeListener()
  }

  activateIndex(index) {
    this.activeValue = index == this.indexValue
  }

  activeValueChanged() {
    this._updateView()
  }

  updateContainerHeight() {
    if (!this.activeValue) { return }

    this._updateContainerHeight()
  }

  leaveUnlessActive() {
    // console.log("Checking...")
    if (!this.activeValue){
      this._hide()
    }
  }

  _updateView() {
    if (this.activeValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show() {
    this._attachResizeListener()
    // this.element.classList.remove("hidden")

    this._updateContainerHeight()
    this._enterTransitions()
    this.transitionControllers.forEach((c) => c.enter())
  }

  async _hide() {
    this._detachResizeListener()
    // this.element.classList.add("hidden")
    this._leaveTransitions()
  }

  async _updateContainerHeight() {
    await nextAnimationFrame()

    const height = this.element.clientHeight

    this.sliderController.updateSlideContainerHeight(height)
  }

  async _enterTransitions() {
    await nextAnimationFrame()

    this.transitionControllers.forEach((c) => c.enter())
  }

  async _leaveTransitions() {
    await nextAnimationFrame()

    this.transitionControllers.forEach((c) => c.leave())
  }


  _attachResizeListener() {
    window.addEventListener("resize", this.resizeListener, { passive: true })
  }

  _detachResizeListener() {
    window.removeEventListener("resize", this.resizeListener)
  }

  _handleResize() {
    if (this.activeValue){
      this._updateContainerHeight()
    }
  }

}
