import { Controller } from "@hotwired/stimulus"

const MODAL_SELECTOR = ".modal"

// Connects to data-controller="modal_control"
export default class extends Controller {
  static values = {
    selector: String
  }

  get modalTarget() {
    if (this.selectorValue) {
      return document.querySelector(this.selectorValue)
    } else {
      return this.element.closest(MODAL_SELECTOR)
    }
  }
  get modalController() { return this.application.getControllerForElementAndIdentifier(this.modalTarget, "modal") }

  connect() {}

  open() {
    const modal = this.modalController

    modal.open()
  }


  close() {
    const modal = this.modalController

    modal.close()
  }
}
